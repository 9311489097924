// 
// menu.scss
//

.nav-second-level,
.nav-thrid-level {
    li {
        a {
            padding: 8px 20px;
            color: $menu-item;
            display: block;
            position: relative;
            transition: all 0.4s;
            &:focus,
            &:hover {
                color: $menu-item-hover;
            }
        }
        > a.active {
            color: $menu-item-active;
        }
    }
}


// Wrapper
#wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

//Content Page
.content-page {
    margin-left: $leftbar-width;
    overflow: hidden;
    padding: 0px 12.5px 5px 12.5px;
    min-height: 80vh;
    margin-top: $topbar-height;
}


// Sidemenu
.left-side-menu {
    width: $leftbar-width;
    background: $bg-leftbar-light;
    bottom: 0;
    // padding: 30px 0;
    padding: 0 0 30px 0;
    position: fixed;
    top: $topbar-height;
    transition-duration: .2s;
    box-shadow: 1px 0.05rem 0.01rem rgba(75,75,90,.075);
    z-index: 99;

    .sidebar-content {
        height: 100%;
        padding-bottom: 30px;
    }
}


// Sidebar
#sidebar-menu {
    padding-bottom: 30px;
    >ul {
        >li {
            &.mm-active{
                >a{
                    border-left: 3px solid $menu-item-active;
                    color: $menu-item-active;
                    background-color: $menu-item-bg-hover;
                    svg {
                        color: $menu-item-active;
                        fill: rgba($menu-item-active, 0.10);
                    }
                }
            }
            >a {
                color: $menu-item;
                display: block;
                padding: 12px 30px;
                font-size: 15px;
                font-weight: 400;
                position: relative;
                transition: all 0.4s;
                border-left: 3px solid transparent;

                svg {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin: 0 10px 0 3px;
                    color: $menu-item;
                    fill: rgba($menu-item, 0.12);
                }

                &:hover,
                &:focus,
                &:active {
                    color: $menu-item-hover;
                    text-decoration: none;
                    background-color: $menu-item-bg-hover;
                    
                    svg {
                        color: $menu-item-active;
                        fill: rgba($menu-item-active, 0.10);
                    }
                }
                >span {
                    vertical-align: middle;
                }

                i {
                    display: inline-block;
                    line-height: 1rem;
                    margin: 0 10px 0 3px;
                    text-align: center;
                    vertical-align: middle;
                    width: 20px;
                }

                &.active {
                    border-left: 3px solid $menu-item-active;
                    color: $menu-item-active;
                    background-color: $menu-item-bg-hover;

                    svg {
                        color: $menu-item-active;
                        fill: rgba($menu-item-active, 0.10);
                    }
                }
               
            }
            
            
            > ul {
                padding-left: 43px;

                ul {
                    padding-left: 20px;
                }
            }
        }
    }
    .menu-arrow {
        transition: transform .15s;
        position: absolute;
        right: 30px;
        display: inline-block;
        font-family: 'unicons';
        text-rendering: auto;
        line-height: 1.3rem;
        font-size: 0.9rem;
        transform: translate(0, 0);
        top: 15px;
        &:before {
            content: "\e82f";
        }
    }
    
    .badge{
        margin-top: 3px;
    }

    .nav-second-level{
        >li{
            .menu-arrow{
                top: 8px;
            }
        }
    }

    li.mm-active {
        > a {
            > span.menu-arrow {
                transform: rotate(90deg);
            }
        }
    }

    .menu-title {
        padding: 15px 30px 10px 30px;
        letter-spacing: .05em;
        pointer-events: none;
        cursor: default;
        font-size: 0.6875rem;
        text-transform: uppercase;
        color: $menu-item;
    }
}

// user profile
.user-profile {
    padding: 15px 20px 15px 30px;

    .avatar-xs {
        display: none;
    }

    .pro-user-name {
        color: $menu-item;
        font-weight: $font-weight-semibold;
        white-space: nowrap;
    /* width: 50px; */
    overflow: hidden;
    text-overflow: ellipsis;
    }

    .pro-user-desc {
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 700;
        letter-spacing: 1px;
    }

    .dropdown-toggle {
        svg {
            width: 18px;
            height: 18px;
            color: $menu-item;
            background-color: rgba($menu-item, 0.10);
            border-radius: 3px;
        }
    }
}

// small menu bar
.left-side-menu-condensed {
    .logo {
        width: $leftbar-width-collapsed !important;
        text-align: center;
        span{
            &.logo-lg {
                display: none;
            }
            &.logo-sm {
                display: block !important;
            }
        }
    }

    // Topbar
    .navbar-custom {
        left: 0 !important;
        padding-left: 10px;
        .button-menu-mobile.disable-btn{
            display: block !important;
        }
    }

    // Side menu
    .left-side-menu {
        position: absolute;
        width: $leftbar-width-collapsed !important;
        z-index: 5;

        .slimScrollDiv,
        .slimscroll-menu {
            overflow: inherit !important;
            height: auto !important;
        }
        .slimScrollBar {
            visibility: hidden;
        }

        .sidebar-content {
            height: auto;
        }

        // Sidebar Menu
        #sidebar-menu {
            .menu-title,
            .menu-arrow,
            .label,
            .badge {
                display: none !important;
            }

            > ul {
                > li {
                    position: relative;
                    white-space: nowrap;

                    > a {
                        padding: 15px;
                        min-height: 54px;
                        transition: none;
                        margin: 0px;
            
                        &:hover,
                        &:active,
                        &:focus {
                            color: $menu-item-hover;
                        }

                        svg{
                            width: 26px;
                        }

                        span {
                            display: none;
                            padding-left: 25px;
                            vertical-align: baseline;
                        }
                        &.active{
                            background-color: $menu-item-bg-hover;
                        }

                    }
                    
                    &:hover  {
                        > a {
                            position: relative;
                            width: calc(190px + #{$leftbar-width-collapsed});
                            color: $menu-item-active !important;
                            background-color: $menu-item-bg-hover;
                            transition: none;

                            span {
                                display: inline;
                            }
                        }

                        a.open,a.mm-active {
                            :after {
                                display: none;
                            }
                        }

                        > ul {
                            display: block;
                            left: $leftbar-width-collapsed;
                            position: absolute;
                            width: 190px;
                            height: auto !important;
                            box-shadow: $box-shadow-sm;

                            ul {
                                box-shadow: $box-shadow-sm;
                            }
                            a {
                                box-shadow: none;
                                padding: 8px 20px;
                                position: relative;
                                width: 190px;
                                z-index: 6;
                                &:hover {
                                    color: $menu-item-hover;
                                }
                            }
                        }
                    }
                }

                ul {
                    padding: 5px 0;
                    z-index: 9999;
                    display: none;
                    background-color: $bg-leftbar-light;

                    li {
                        &:hover {
                            > ul {
                                display: block;
                                left: 190px;
                                margin-top: -36px;
                                height: auto !important;
                                position: absolute;
                                width: 190px;
                            }
                        }

                        > a {
                            span.pull-right {
                                position: absolute;
                                right: 20px;
                                top: 12px;
                                transform: rotate(270deg);
                            }
                        }
                    }
                    li.active {
                        a {
                            color: $menu-item-hover-color-dark;
                        }
                    }
                }
            }
        }
    }

    .user-profile {
        padding: 15px;
    
        .avatar-sm {
            display: none;
        }

        .avatar-xs {
            display: block;
        }

        .pro-user-name,
        .pro-user-desc {
            display: none;
        }
    
        .profile-dropdown-menu {
            .dropdown-toggle {
                svg {
                    background-color: transparent;
                }
            }
        }
    }

    // Content Page
    .content-page {
        margin-left: $leftbar-width-collapsed !important;
    }

    //Footer
    .footer {
        left: $leftbar-width-collapsed !important;
    }

    //User box
    .user-box {
        display: none;
    }
}

// Body min-height set

@media (min-width: 769px){
    body.left-side-menu-condensed {
        min-height: 1200px;
    }
}

@media (max-width: 1024px){
    .left-side-menu{
        top: $topbar-height;
        padding: 12px 0;
    }

    .left-side-menu-condensed {
        .left-side-menu{
            padding-top: 12px;
        }
    }
}


@include media-breakpoint-down(sm) {
    body {
        overflow-x: hidden;
    }
    .left-side-menu {
        display: none;
        z-index: 10 !important;
        top: $topbar-height;
    }
    .sidebar-enable {
        .left-side-menu {
            display: block;
        }
    }
    .content-page,.left-side-menu-condensed .content-page {
        margin-left: 0 !important;
        padding: 0 10px;
    }
    .pro-user-name {
        display: none;
    }
    .logo-box {
        display: none;
    }
}



/* =============
  Menu - Dark
============= */

// Leftbar-dark
.left-side-menu-dark {
    .metismenu {
        li {
            a[aria-expanded=true]{
                color: $menu-item-active-color-dark !important;
            }
        }
    }
    .left-side-menu {
        background-color: $bg-leftbar-dark;
        box-shadow: none;
        #sidebar-menu {
            > ul {
                > li{
                    &.mm-active{
                        >a{
                            color: $menu-item-active-color-dark;
                            background-color: lighten($bg-leftbar-dark, 4%);
                            svg{
                                color: $menu-item-active-color-dark;
                                fill: rgba($white,.12);
                            }
                        }
                    }
                    > a {
                        color: $menu-item-color-dark;
        
                        svg{
                            color: $menu-item-color-dark;
                            fill: rgba($white,.12);
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            color: $menu-item-hover-color-dark;
                            background-color: lighten($bg-leftbar-dark, 4%);
                        }

                        &.active {
                            color: $menu-item-active-color-dark;
                            background-color: lighten($bg-leftbar-dark, 4%);
                            svg{
                                color: $menu-item-active-color-dark;
                                fill: rgba($white,.12);
                            }
                        }
                    }
                }
            }

            .menu-title {
                color: $gray-500;
            }
        }
    }

    .nav-second-level,
    .nav-thrid-level {
        li {
            a {
                color: $menu-item-color-dark;
                &:focus,
                &:hover {
                    background-color: transparent;
                    color: $menu-item-hover-color-dark;
                }
            }
            &.mm-active {
                >a {
                    color: $menu-item-active-color-dark;
                }
            }
        }
    }

    &.left-side-menu-condensed {
        .left-side-menu {
            #sidebar-menu{
                >ul{
                    >li{
                        &:hover>a{
                            background-color: lighten($bg-leftbar-dark, 4%);
                            color: $menu-item-active-color-dark !important;
                        }
                    }
                }
            }
        }

        .nav-second-level,
        .nav-thrid-level {
            li {
                &.mm-active {
                    >a {
                        color: $menu-item-active;
                    }
                }
            }
        }
    }
    .user-profile {
        .pro-user-name,
        .pro-user-desc {
            color: $gray-500;
        }
    
        .profile-dropdown-menu {
            .dropdown-toggle {
                svg {
                    background-color: lighten($bg-leftbar-dark, 4%);
                    color: $white;
                }
            }
        }
    }
}
.vertical-center{
    min-height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
}
.login-btn{
    background-color: #de5801;
    padding: 10px 0px;
    font-size: 16px !important;
    font-weight: bold;
}
.login-btn:hover{
    background-color: #bb4f0a;
    color: white;
}
.logo1{
    height: 65px;
}
.logo2{
    height: 50px;
}
.left-side-menu{
    top: 0px;
}
.navbar-custom{
    left:250px !important;
    background-color: #212429 !important;
}
.navbar-custom .topnav-menu .nav-link{
    color: #fff;
}
.img-brd{
    border-right: 1px solid rgba(0, 0, 0, 0.5);
    padding-right: 5px;
}
.img-brd2{
    border-left: 1px solid rgba(0, 0, 0, 0.5);
    padding-left: 20px;
    margin-left: -15px;

}
.navbar-custom .topnav-menu .nav-link svg {
    color: #fff !important;
}
.right-nav li a{
    color: #131414;
    font-weight: 600;
}
.right-nav li a:hover{
    background-color: #e44a02;
    color: #fff;
}
.right-nav li a:hover svg{
    color: #fff;
}
.topserach{
    min-width: 16rem;
    padding: 0px;
}
.media {
    display: block;
    align-items: center;
    text-align: center;
}
.avatar-sm {
    height: 5rem !important;
    width: 5rem !important;
    margin-top: 20px;
}
.page-title {
    background: #e5e5e5;
    padding: 10px 0 !important;
}
.content-page{
    padding: 0px;
}
body {
    font-family: 'Montserrat', sans-serif;
   // background-color: #fff;
   color: #212529;
}
.home-navbar {
    background-color: #ffffff !important;
}
.home-navbar li a{
    color: #212529; 
    font-weight: bold;
    padding-right: 2rem !important;
    text-transform: uppercase;
}
.navbar-light .navbar-nav .nav-link{
    color: #212529; 
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus{
    color: #f87131;
}
.btn{
    border-radius: 0.2rem;
    font-size: 12px;
    font-weight: 500;
}
.dash-tab {
    border-bottom: none !important;
}
.dash-tab li a {
    border-color: transparent !important;
    padding: 0.5rem 2.25rem;
    text-transform: uppercase;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 12px;
    color: #909192;
    cursor: pointer;
}
.dash-tab li a.active {
    border-bottom: none;
}
.dash-tab .nav-link.active, .dash-tab .nav-item.show .nav-link {
    color: #fff;
    background-color: #f87131;
    border-color: #dee2e6 #dee2e6 #fff;
}
.navbar-light .navbar-nav .active > .nav-link{
    color: #f87131;
}
.nav-tabs .nav-link {
    border: none;
    border-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    //margin: 0px 30px;
}

.requirement-tab {
    border-bottom: none !important;
    padding: 31px 0px !important;
    // display: grid;
    // grid-template-columns: auto auto auto;
    // margin-left: 12px;
    // margin-right: 7px;
}
.requirement-tab li a {
    border: 1px solid #909192 !important;
    padding: 0.75rem 2.25rem;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.requirement-tab li a.active {
    border-bottom: none;
}
.requirement-tab .nav-link.active, .requirement-tab .nav-item.show .nav-link {
    color: #fff;
    background-color: #f87131;
    border: 1px solid #f87131 !important;
}
.requirement-tab .nav-link:hover{
background-color: #f87131;
border: 1px solid #f87131 !important;
color: #ffffff;
}
.style-input, .data-table-extensions > .data-table-extensions-filter > .filter-text {
    background: transparent;
    border: none;
    border-bottom: 1px solid #212529 !important;
    border-radius: 0px;
}
#search-bar-0{
    background: transparent;
    border: none;
    border-bottom: 1px solid #212529 !important;
    border-radius: 0px;
}
.style-textarea {
    background: transparent;
    border: 1px solid #212529;
}
.redspan{
    color: red;
}
.req-tab-content {
    padding: 30px 0 0 0;
}
label {
    font-weight: 500;
}
.bg-secondary {
    color: #fff !important;
    background-color: #212429 !important;
    border-color: #212429 !important;
}
.NfIlA{
    z-index: -1;
}
.table{
    color: #212529;
    margin-top: 20px;
}
.table thead{
    background-color: #212429 !important;    
    color: #ffffff !important;
    
}
.table thead th{
    border-color: #212429;
    font-weight: normal;
}
.download, .print{
    display: inline-block !important;
    font-weight: 400 !important;
    color: #6c757d !important;
    text-align: center !important;
    vertical-align: middle !important;
    -webkit-user-select: none !important;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.table th, .table td {
    border-top: none;
    // padding: 5px 5px;
    // vertical-align: middle;
    text-align: left;
    border-bottom: 1px solid #212429;
}
.table th{
    //padding: 10px 5px;
}
.upbtn{
    padding: 11px;
    margin-right: 5px !important;
}
.optionListContainer{
    position: relative !important;
}
.hr-tab li:last-child a{
    padding: 0.75rem 1.25rem;
}
.search-style{
    background: transparent;
    border: 1px solid #909192 !important;
}
.masternav li a{
    font-weight: bold;
    margin: 0px 25px;
    color: #212529;
}
.masternav li a.active{
    color:#f87131;
}
.second-level-tab{
    border-bottom: none;
}
.second-level-tab li a.active {
    border-bottom: none !important;
    color: #f87131 !important;
    background: transparent !important;
}
// td button svg{
//     height: 18px;
//     width: 18px;
// }
td button i{
    font-size: 20px;
}
td button:hover svg {
    color: #f87131;
}
td button:hover i {
    color: #f87131;
}
.form-control {
    color: rgba(0, 0, 0, 0.5);
}
a:hover {
    cursor: pointer;
}
.btn-link{
    padding: 0px 10px;
}
.upload-li{
    margin-left: -35px;
}
.header-info{
    background-color: #212429 !important;
    color: #ffffff;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 25px;
    padding-left: 35px;
}
.btn-link {
    font-size: 14px;
}
input[type="file"]{
    padding: 0.3rem 0.75rem;
}
.squire{
    height: 40px;
    width: 50px;
} 
.green-bg{
    background-color: #01ca30;
}
.orange-bg{
    background-color: #f16600;
}
.yellow-bg{
    background-color: #ffde00;
}
.red-bg{
    background-color: #e40001;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e5e5e5;
    opacity: 1;
}
.avatar-sm-2 {
    height: 7rem !important;
    width: 7rem !important;
    margin-top: 20px;
}
.notify-item{
    position: relative;
}
.close-notify{
    position: absolute;
    right: -66px;
    background: #212429a6;
    top: 0px;
    padding: 20px 20px;
}
.notify-item:hover .close-notify{
    right: 0px;
}
.green-text{
    color:#01ca30;
    font-weight: bold;
}
.orange-text{
    color:#f16600;
    font-weight: bold;
}
.yellow-text{
    color:#ffde00;
    font-weight: bold;
}
.red-text{
    color:#e40001;
    font-weight: bold;
}
.card-border-radius{
    border-radius: 20px 20px 0px 0px;
}
.dash-leave-details{
    width: 100%;
    padding: 10px;
    background: #373f4a;
    color: #fff;
    text-align: center;
    font-weight: bold;
}